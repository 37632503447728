
import { KEY } from '@/inversify.keys';
import { inject } from '@/inversify';
import { Vue, Component } from 'vue-property-decorator';

import { SettingsGeneralService } from '@/modules/settings/settings-general.service';
import RVSDetailsService from '../rvs-details.service';

type ColumnWidth = number | 'auto';

const IGNORED_SCORED = ['star_rating_score', 'distance_score'];
const FAKE_DATA = Array.from({ length: 8 })
    .map((_, index) => [`______________${index}`, 0]) as [string, number][];

@Component({})
export default class RvsDetails extends Vue {
    @inject(KEY.RVSDetailsService) private rvsDetailsService!: RVSDetailsService;
    @inject(KEY.SettingsGeneralService) private settingsGeneralService!: SettingsGeneralService;

    columns: Record<string, ColumnWidth> = {
        'rvs.details.keyMetrics': 300,
        'titles.score': 300,
        'titles.insights': 'auto',
    };

    get isLoading() {
        return !this.rvsDetailsService.data || this.rvsDetailsService.isLoading;
    }

    get isNoData() {
        return !this.isLoading && this.tableData.length === 0;
    }

    get colors() {
        const { chartColors } = this.settingsGeneralService;

        return this.tableData.map((_, index) => chartColors[index % chartColors.length]);
    }

    get tableData() {
        if (this.isLoading) return FAKE_DATA;
        if (!this.rvsDetailsService.data) return [];

        return Object.entries(this.rvsDetailsService.data.rootCause)
            .filter(([label]) => !IGNORED_SCORED.includes(label))
            .sort((a, b) => b[1] - a[1])
            .map(([label, value]) => [label, value * 100]) as [string, number][];
    }
}
